// ? Theme variables

$dark-main-color: #26263c;
$dark-second-color: #1f1f31;
$dark-light-color: #323249;
$dark-highlight-color: #2187ec;

$dark-bg-color: $dark-main-color;
$dark-title-color: #fff;
$dark-font-color: #a2a3b7;
$dark-main-border: 1px solid #323249;
$dark-border-radius: 4px;
$dark-main-border-color: #323249;

.theme-dark {
  // ? General =======================
  .p-field > label,
  input,
  textarea,
  label,
  .p-tabview .p-tabview-nav li .p-tabview-nav-link,
  .p-chips .p-chips-multiple-container .p-chips-input-token input {
    color: $dark-font-color;
  }
  h2,
  h4 {
    color: $dark-title-color;
  }

  // ? Utility =======================

  // ? Main ==========================
  .header-main-logo {
    filter: brightness(0) invert(1);
  }
  .main-content {
    background-color: $dark-bg-color;
  }
  .main-card .card-title {
    color: $dark-title-color;
  }
  // Sidebar
  nav {
    background-color: $dark-second-color !important;
    border-right: $dark-main-border;
    .nav-top-header {
      background-color: $dark-second-color !important;
      border-bottom: $dark-main-border;
      @include xs {
        border-bottom: none;
      }
    }
  }
  // Chart
  .dashboard-card {
    background-color: $dark-second-color;
    // border: $dark-main-border;
    border-radius: $dark-border-radius;
    padding: 1.5rem;
    border: none;

    @include xs {
      padding: 1rem;
    }

    span {
      color: $dark-font-color;
    }

    &.numbers {
      h1 {
        color: #fff;
      }
      span {
        color: $dark-font-color;
      }
    }
  }
  // Header
  .top-header {
    background-color: $dark-main-color !important;
    border-bottom: $dark-main-border;
    box-shadow: none !important;
    .user-options .user-img {
      color: $dark-highlight-color;
      background-color: #d9ecff05 !important;
    }
    .user-options .user-option-dropdown {
      box-shadow: none;
    }
  }
  .top-header .user-options span {
    color: $dark-title-color !important;
  }
  // Sidebar
  .p-sidebar {
    background-color: $dark-main-color;
  }

  // Confirm popup
  .p-confirm-popup .p-confirm-popup-footer {
    background-color: $dark-second-color !important;
    background: $dark-second-color !important;
  }
  // Tabs
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border-bottom: 3px solid transparent;
  }
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link:focus,
  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
  }
  .p-tabview
    .p-tabview-nav
    li:not(.p-highlight):not(.p-disabled):hover
    .p-tabview-nav-link {
    background-color: transparent;
    border-bottom: 3px solid $dark-second-color;
    color: $dark-title-color;
  }
  .p-tabview .p-tabview-nav {
    background-color: transparent;
    border: none;
    border-bottom: $dark-main-border;
  }
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background-color: transparent !important;
    color: #fff;
    border-bottom: 3px solid $dark-highlight-color !important;
  }
  // Breadcrumb
  .p-breadcrumb {
    background: transparent;
  }
  .p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
    color: $dark-highlight-color;
  }
  .p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
    color: $dark-font-color;
    transition: color 0.3s;

    &:hover {
      color: $dark-title-color;
      transition: color 0.3s;
    }
  }
  .p-breadcrumb ul li .p-menuitem-link:focus {
    box-shadow: none;
  }
  .p-breadcrumb ul li:last-child .p-menuitem-link .p-menuitem-text {
    color: $dark-title-color;
    cursor: default;
  }
  // ? File upload & Media library
  .media-preview {
    span,
    i {
      color: $dark-font-color;
    }
  }
  .p-fileupload .p-fileupload-buttonbar {
    background-color: darken($dark-second-color, 1%);
    border: none;
  }
  .p-fileupload .p-fileupload-content {
    background-color: $dark-second-color;
    border: none;
  }
  .p-fileupload .drag-and-drop {
    background-color: $dark-second-color;
    border: none;
    .drop-border {
      background-image: repeating-linear-gradient(
          0deg,
          $dark-main-border-color,
          $dark-main-border-color 14px,
          transparent 14px,
          transparent 34px,
          $dark-main-border-color 34px
        ),
        repeating-linear-gradient(
          90deg,
          $dark-main-border-color,
          $dark-main-border-color 14px,
          transparent 14px,
          transparent 34px,
          $dark-main-border-color 34px
        ),
        repeating-linear-gradient(
          180deg,
          $dark-main-border-color,
          $dark-main-border-color 14px,
          transparent 14px,
          transparent 34px,
          $dark-main-border-color 34px
        ),
        repeating-linear-gradient(
          270deg,
          $dark-main-border-color,
          $dark-main-border-color 14px,
          transparent 14px,
          transparent 34px,
          $dark-main-border-color 34px
        ) !important;
      background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
      background-position: 0 0, 0 0, 100% 0, 0 100%;
      background-repeat: no-repeat;

      span {
        color: $dark-font-color;
      }
    }
  }
  .p-accordion
    p-accordiontab:last-child
    .p-accordion-header:not(.p-highlight)
    .p-accordion-header-link {
    background-color: $dark-main-color;
    border: none;
    color: $dark-font-color;
    font-weight: 500;
  }
  .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight
    .p-accordion-header-link {
    background-color: $dark-main-color;
    border-color: transparent;
    border-bottom: $dark-main-border;
    color: $dark-title-color;
    font-weight: 500;
  }
  .p-accordion .p-accordion-content {
    background-color: $dark-main-color;
    border-color: transparent;
  }
  // Panel & reorder
  .page-seo
    .p-accordion
    p-accordiontab:last-child
    .p-accordion-header:not(.p-highlight)
    .p-accordion-header-link {
    background-color: $dark-second-color;
  }
  .page-seo
    .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight
    .p-accordion-header-link {
    background-color: $dark-second-color;
  }
  .p-panel .p-panel-header {
    background-color: darken($dark-second-color, 1%);
    border-color: transparent;
    color: $dark-title-color;
  }
  .main-card h3 {
    color: $dark-title-color;
  }
  .p-panel .p-panel-content {
    background-color: $dark-second-color !important;
    background-color: transparent;
    border-color: transparent;
    // padding-bottom: 0px;
  }
  .reorder-body {
    background-color: $dark-main-color !important;
    border-color: transparent;
    border-radius: $dark-border-radius;
    color: $dark-font-color;
    margin-bottom: 1rem !important;
  }
  .reorder-handle {
    background-color: $dark-second-color !important;
    border-color: transparent;
  }
  .second-btn {
    background-color: $dark-second-color !important;
    border-color: transparent;
    color: $dark-title-color;
  }
  // Tags
  .p-chips .p-chips-multiple-container .p-chips-token {
    color: #fff;
    background-color: $success !important;
  }
  .red-chips .p-chips-multiple-container .p-chips-token {
    background-color: $error !important;
    color: #fff;
  }
  // Modal
  .p-dialog .p-dialog-header,
  .p-dialog .p-dialog-content,
  .p-dialog .p-dialog-footer {
    background-color: $dark-main-color;
  }
  .p-dialog .p-dialog-header .p-dialog-title {
    color: $dark-title-color;
  }
  .p-dialog .p-dialog-footer button {
    color: #fff;
  }
  // Menu reorder
  .menu-item {
    background-color: $dark-main-color !important;
    border-color: transparent !important;
    span {
      color: #fff;
    }
  }
  .submenu {
    background-color: $dark-second-color !important;
  }
  // Skeleton
  .p-skeleton {
    background-color: rgba(31, 31, 49, 0.06);
  }
  .p-skeleton:after {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.04),
      rgba(255, 255, 255, 0)
    );
  }
  // Tables
  .p-datatable .p-datatable-tbody > tr {
    background-color: transparent;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    border: none;
    border-bottom: $dark-main-border !important;
    color: $dark-title-color;
  }
  .p-datatable .p-datatable-thead > tr > th {
    background-color: $dark-light-color;
    color: $dark-title-color;
    border: none;
  }
  .p-datatable .p-datatable-header {
    border-color: transparent;
    background-color: transparent;
    padding-right: 0px;
    @include xs {
      padding: 1rem;
    }
  }
  .p-paginator {
    background-color: transparent;
  }
  // Image upload
  .image-upload .upload-item {
    background-color: $dark-main-color;
    padding: 0.5rem;
    border-radius: $border-radius;
  }
  .p-fileupload .p-fileupload-content {
    padding: 1rem;
  }
  // Help component
  .help-component {
    .p-tag.p-tag-info {
      background-color: $dark-highlight-color;
    }
    span {
      color: $dark-font-color;
    }
    .p-tag {
      span {
        color: $dark-title-color;
      }
    }
  }
  // ? Block UI
  .block-ui .block-ui-message {
    background-color: $dark-main-color !important;

    .pi-spinner:before {
      color: $dark-highlight-color;
    }
  }

  // ? Progress bar
  .items-for-upload {
    background-color: darken($dark-second-color, 1%) !important;
    border: none !important;
    .single-item {
      background-color: $dark-bg-color !important;
      border: none !important;
      span,
      i {
        color: $dark-title-color !important;
      }
    }
  }

  // ? Buttons
  .main-btn.disabled {
    background-color: #d5daea21;
    color: #a9a9c1;
    cursor: no-drop;
  }
  .main-btn,
  .image-upload .file > label,
  .p-button {
    background-color: $dark-highlight-color;
    &:hover {
      background-color: darken($dark-highlight-color, 5%);
    }
  }
  .p-button.p-button-text {
    background-color: transparent;
  }
  // ? Placeholders
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: darken($dark-font-color, 30%);
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: darken($dark-font-color, 30%);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: darken($dark-font-color, 30%);
  }

  // ? Forms =========================
  .p-dropdown .p-dropdown-label.p-placeholder {
    color: darken($dark-font-color, 30%) !important;
  }
  .ql-editor.ql-blank::before {
    color: darken($dark-font-color, 30%);
  }
  .p-inputtext,
  .ql-editor,
  .p-dropdown,
  .ql-container.ql-snow {
    background-color: $dark-second-color;
    border: 1px solid $dark-second-color !important;
    color: $dark-title-color !important;
  }
  .p-button:focus {
    box-shadow: none;
  }
  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: $dark-main-color;
    color: $dark-title-color;
  }
  .ql-toolbar {
    background-color: darken($dark-second-color, 1%) !important;
    border: 1px solid $dark-second-color !important;
  }
  .ql-snow .ql-stroke {
    stroke: $dark-title-color;
  }
  .ql-snow.ql-toolbar button svg,
  .ql-snow .ql-toolbar button svg {
    stroke: $dark-title-color;
  }
  .ql-snow .ql-picker-label {
    color: $dark-title-color;
  }
  .p-dropdown-panel {
    background-color: $dark-second-color;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background-color: $dark-main-color;
    color: $dark-title-color;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    color: $dark-font-color;
  }
  .p-inputswitch.p-focus .p-inputswitch-slider {
    box-shadow: none;
  }
  .p-inputtext:enabled:focus,
  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 0.1rem $dark-highlight-color;
  }
  .p-selectbutton .p-button {
    background-color: $dark-second-color;
    color: $dark-font-color;
    border: transparent;
  }
  .p-selectbutton .p-button.p-highlight {
    background-color: $dark-highlight-color !important;
    color: #fff;
  }
  .p-inputswitch .p-inputswitch-slider {
    background: $dark-second-color;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: $dark-highlight-color;
  }

  // ? Buttons =======================
  .main-btn.back-btn {
    background-color: #d9ecff05;
  }
}
