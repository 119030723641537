@import "./mixins";

// * BUTTONS

.main-btn {
  background-color: $main-color;
  color: #fff;
  border: none;
  padding: 0.68rem 0.8rem;
  border-radius: $border-radius;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;

  // overflow: hidden;
  // position: relative;
  // display: inline-block;

  // &::before {
  //     content: '';
  //     display: block;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     width: 100%;
  //     height: 100%;
  //     background: darken($main-color, 30%);
  //     opacity: 0.1;
  //     transform: scaleX(0);
  //     transform-origin: 100% 100%;
  //     transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  //     will-change: transform;
  // }

  // &:hover::before  {
  //     transform-origin: 0 0;
  //     transform: scaleX(1);
  // }

  &.p-button {
    padding: 0.55rem 0.8rem;
  }

  .p-button-label {
    display: none;
  }

  i {
    margin-left: 2rem;
  }
  &.only-icon-btn {
    i {
      margin: 0px;
    }
  }
  &.add-new-btn {
    min-width: 160px;
  }
  &.back-btn {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.disabled {
    background-color: darken($app-bg, 10%);
    color: lighten($second-color, 30%);
    &:hover {
      background-color: darken($app-bg, 10%);
    }
  }

  &:hover {
    background-color: darken($main-color, $darken-amount);
    transition: all 0.3s;
    color: #fff;
  }
  &:focus {
    outline: none;
    border: none;
  }
  &.main-btn-outlined {
    background-color: transparent !important;
    border: 1px solid $main-color;
    color: $main-color;
    padding: 0.67rem 0.8rem;
    &:hover {
      background-color: $main-color !important;
      color: #fff;
    }
  }
  &.secondary-btn {
    background-color: #607d8b;
    color: #fff;
    transition: all 0.3s;
    &:hover {
      background-color: darken(#607d8b, $darken-amount);
      color: #fff;
    }
  }
}

.clear-btn {
  background-color: transparent;
  color: $main-color;
  border: none;
  padding: 0.68rem 0rem;
  border-radius: $border-radius;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.second-btn {
  background-color: $app-bg;
  color: $text-color;
  border: 1px solid $main-border;
  padding: 0.7rem 0.8rem;
  border-radius: $border-radius;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
  &:hover {
    color: $text-color;
    background-color: darken($app-bg, $darken-amount);
    transition: all 0.3s;
  }
  &:focus {
    outline: none;
    border: none;
  }
}

.small-btn {
  padding: 0.3rem 0.4rem;
  i {
    font-size: 0.8rem;
  }
}

.reorder-handle {
  padding: 0.3rem 0.4rem;
  border: 1px solid $main-border;
  border-radius: $border-radius;
  cursor: pointer;

  @include xs {
    text-align: center;
    &.responsive-handle {
      width: 100%;
    }
  }

  i {
    color: $secondary;
    transition: all 0.3s;
  }
  &:active {
    i {
      color: $main-color;
      transition: all 0.3s;
    }
  }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;

  &:hover {
    .main-btn {
      background-color: darken($main-color, $darken-amount);
      transition: all 0.3s;
    }
  }

  input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}

.close-bar {
  width: 100%;
  height: 40px;
  background-color: transparent;
  border: none;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .bar {
    width: 30%;
    height: 5px;
    border-radius: 10px;
    background: $main-border;
  }
}
