// * VARIABLES

// ? Global
$border-radius:3px;
$darken-amount:6%;

// ? Font
$font-size: .9rem;
$font-family: 'Rubik', sans-serif;
$text-color: #24292E;

// ? Colors
$main-color:#2196F3;
$second-color:#24292E;
$app-bg:#F7F8FB;
$main-border:#E1E4E8;

// ? Sidebar
$sidebar-bg: #1e1e2d;
$active-item:#1b1b28;

// ? Colors
$red: #FC035F;
$yellow: #FBBF24;
$green: #34D399;
$secondary: #b7bec9;
$dark: #1F2937;
$blue: #3B82F6;

$info: #3B82F6;
$success: #00cf8a;
$warn: #FBBF24;
$error: #FC035F;
