* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
}

body {
  background-color: #F7F8FB;
}

label, p, span, input, select, textarea,
a, td, th, strong, i, button, .p-inputtext,
.p-inline-message .p-inline-message-text, div {
  font-size: 0.9rem;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9ea9b3;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9ea9b3;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #9ea9b3;
}

a {
  text-decoration: none;
  color: #3B82F6;
}
a:hover {
  color: #0b63f3;
}

.main-btn {
  background-color: #2196F3;
  color: #fff;
  border: none;
  padding: 0.68rem 0.8rem;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-btn.p-button {
  padding: 0.55rem 0.8rem;
}
.main-btn .p-button-label {
  display: none;
}
.main-btn i {
  margin-left: 2rem;
}
.main-btn.only-icon-btn i {
  margin: 0px;
}
.main-btn.add-new-btn {
  min-width: 160px;
}
.main-btn.back-btn {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-btn.disabled {
  background-color: #d5daea;
  color: #677684;
}
.main-btn.disabled:hover {
  background-color: #d5daea;
}
.main-btn:hover {
  background-color: #0d87e9;
  transition: all 0.3s;
  color: #fff;
}
.main-btn:focus {
  outline: none;
  border: none;
}
.main-btn.main-btn-outlined {
  background-color: transparent !important;
  border: 1px solid #2196F3;
  color: #2196F3;
  padding: 0.67rem 0.8rem;
}
.main-btn.main-btn-outlined:hover {
  background-color: #2196F3 !important;
  color: #fff;
}
.main-btn.secondary-btn {
  background-color: #607d8b;
  color: #fff;
  transition: all 0.3s;
}
.main-btn.secondary-btn:hover {
  background-color: #536d79;
  color: #fff;
}

.clear-btn {
  background-color: transparent;
  color: #2196F3;
  border: none;
  padding: 0.68rem 0rem;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.second-btn {
  background-color: #F7F8FB;
  color: #24292E;
  border: 1px solid #E1E4E8;
  padding: 0.7rem 0.8rem;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
}
.second-btn:hover {
  color: #24292E;
  background-color: #e3e6f1;
  transition: all 0.3s;
}
.second-btn:focus {
  outline: none;
  border: none;
}

.small-btn {
  padding: 0.3rem 0.4rem;
}
.small-btn i {
  font-size: 0.8rem;
}

.reorder-handle {
  padding: 0.3rem 0.4rem;
  border: 1px solid #E1E4E8;
  border-radius: 3px;
  cursor: pointer;
}
@media (max-width: 575.98px) {
  .reorder-handle {
    text-align: center;
  }
  .reorder-handle.responsive-handle {
    width: 100%;
  }
}
.reorder-handle i {
  color: #b7bec9;
  transition: all 0.3s;
}
.reorder-handle:active i {
  color: #2196F3;
  transition: all 0.3s;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.upload-btn-wrapper:hover .main-btn {
  background-color: #0d87e9;
  transition: all 0.3s;
}
.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.close-bar {
  width: 100%;
  height: 40px;
  background-color: transparent;
  border: none;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.close-bar .bar {
  width: 30%;
  height: 5px;
  border-radius: 10px;
  background: #E1E4E8;
}

.main-card {
  border-radius: 4px;
  width: 100%;
  padding: 1.9rem 0px;
}
.main-card h3 {
  text-transform: uppercase;
  font-weight: 500;
}
.main-card .hr {
  width: 100%;
  height: 1px;
  background-color: #E1E4E8;
}
.main-card .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-card .card-title {
  margin-top: 1.2rem;
}
@media (max-width: 575.98px) {
  .main-card h1.card-title {
    margin-top: 0px;
  }
}
.main-card .card-body {
  margin-top: 1.9rem;
}
.main-card .card-title-section {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-card .card-title-section .card-title {
  margin-top: 0px;
}

.card {
  border: 1px solid #E1E4E8;
  background-color: #fff;
  padding: 1.9rem;
  border-radius: 5px;
}

.toggle-filter-btn {
  display: none;
}
@media (max-width: 575.98px) {
  .toggle-filter-btn {
    display: flex;
  }
}

.page-control {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: all 0.3s;
  opacity: 1;
  visibility: visible;
}
.page-control .filter-dark-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  display: none;
}
@media (max-width: 575.98px) {
  .page-control .filter-dark-bg {
    display: block;
  }
}
.page-control .close-bar {
  display: none;
}
@media (max-width: 575.98px) {
  .page-control .close-bar {
    display: flex;
  }
}
.page-control.toggle-filters {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  margin-bottom: -200px;
}
@media (max-width: 575.98px) {
  .page-control {
    flex-flow: column;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 1rem;
    padding-top: 0px;
    background: #fff;
    z-index: 90;
    box-shadow: 0 -10px 20px #0000000d;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
}
.page-control .filter-buttons {
  display: flex;
  align-items: center;
}
@media (max-width: 575.98px) {
  .page-control .filter-buttons {
    flex-flow: row;
  }
}
.page-control .input-fields {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}
@media (max-width: 575.98px) {
  .page-control .input-fields .p-inputgroup, .page-control .input-fields .p-input-icon-left {
    width: 100% !important;
    margin-right: 0px !important;
  }
  .page-control .input-fields .filter-item {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}
@media (max-width: 575.98px) {
  .page-control .input-fields .dropdown-width {
    min-width: 100% !important;
  }
}
@media (max-width: 575.98px) {
  .page-control .input-fields {
    width: 100%;
    flex-flow: column;
    margin-right: 0px;
  }
}

.item-img {
  width: 80px;
  height: 80px;
  background-size: cover;
  background-position: center;
  border-radius: 3px;
}

@media (max-width: 575.98px) {
  .reorder-description {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .reorder-body {
    flex-flow: column;
  }
}
@media (max-width: 575.98px) {
  .reorder-body .reorder-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.reorder-action-buttons {
  display: flex;
  align-items: center;
}
@media (max-width: 575.98px) {
  .reorder-action-buttons {
    margin-top: 1rem;
  }
}
.reorder-action-buttons .small-btn {
  margin-top: -5px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1e1e2d;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2196F3;
}

.sidebar-in-progress span::after {
  content: "In progress";
  position: absolute;
  font-size: 0.6rem;
  padding: 0.2rem 0.3rem;
  border-radius: 3px;
  float: right;
  right: 10px;
  top: 50%;
  transform: translate(0%, -50%);
  color: #24292E;
  background-color: #FBBF24;
}

.p-border {
  border: 1px solid #dee2e6;
}

.w-100 {
  width: 100% !important;
}

.w-fit-content {
  width: fit-content !important;
}

.w-50 {
  width: 50%;
}

.dropdown-width {
  width: 200px;
}

.w-btn-group {
  width: 65px;
}
@media (max-width: 575.98px) {
  .w-btn-group {
    display: inline-block !important;
    width: 90px;
  }
}

.in-progress {
  color: var(--pink-500);
  display: flex;
  align-items: center;
}
.in-progress i {
  font-size: 2rem;
  margin-right: 1rem;
}

.bg-secondary {
  background-color: #b7bec9 !important;
}
.bg-secondary:hover {
  background-color: #a3acba !important;
}

.bg-light {
  background-color: #d5daea !important;
}

.bg-dark {
  background-color: #1F2937 !important;
}

@media (max-width: 575.98px) {
  .p-sm-mt-0 {
    margin-top: 0px !important;
  }
}

.theme-dark .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  text-align: center;
}

.p-progressbar-determinate .p-progressbar-label {
  font-size: 0.7rem;
}

@media (max-width: 575.98px) {
  .p-dialog {
    min-width: 90vw !important;
  }
}
.p-dialog .p-dialog-content {
  height: 80vh !important;
}

.p-field {
  display: flex;
  flex-flow: column;
}

.p-tabview .p-tabview-panels,
.p-tabview .p-tabview-nav li .p-tabview-nav-link,
.p-tabview .p-tabview-nav,
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: transparent;
}

.p-tabview .p-tabview-panels {
  padding: 1rem 0px;
}

.p-datatable .p-datatable-tbody > tr:hover {
  background: #f8f9fa;
}

.p-selectbutton .p-button {
  padding: 0.57rem 1rem !important;
}

.p-tag-value {
  font-size: 0.75rem;
  font-weight: 400;
}

.p-button.p-button-icon-only.main-btn {
  padding: 0.68rem 0.8rem;
}

.p-toast {
  opacity: 1;
}
@media (max-width: 575.98px) {
  .p-toast {
    width: 93vw !important;
    right: 50% !important;
    transform: translate(50%);
  }
}

.p-toast-message-content {
  align-items: center !important;
}

.p-toast .p-toast-message {
  box-shadow: 3px 7px 0px #0000000a;
  border-radius: 4px;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.3rem 0 0 0;
}

.p-toast .p-toast-message.p-toast-message-info {
  background: #3B82F6;
  color: #fff;
}

.p-toast .p-toast-message.p-toast-message-info .p-toast-message-content .p-toast-message-icon {
  background: #3B82F6;
}

.p-toast .p-toast-message.p-toast-message-success {
  border: none;
  background: #00cf8a;
  color: #fff;
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-content .p-toast-message-icon {
  color: #00cf8a;
}

.p-toast .p-toast-message.p-toast-message-error {
  border: none;
  background: #FC035F;
  color: #fff;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-content .p-toast-message-icon {
  color: #FC035F;
}

.p-toast .p-toast-message.p-toast-message-warn {
  border: none;
  background: #FBBF24;
  color: #fff;
}

.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-content .p-toast-message-icon {
  color: #FBBF24;
}

.p-toast .p-toast-message .p-toast-icon-close {
  width: 2rem;
  min-width: 2rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 1.4rem;
  width: 35px;
  background: #fff;
  border-radius: 4px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 35px;
}

.p-tabview .p-tabview-nav {
  border: none;
  border-radius: 3px;
  background: #fff;
  border: 1px solid #dee2e6;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: none;
  font-weight: 400;
  text-transform: uppercase;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:focus {
  border: none;
  outline: none;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #818cf8 !important;
  color: #fff;
  border: none !important;
  padding: 0.9rem 1rem;
  border-radius: 0px;
  height: 100%;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link:focus {
  box-shadow: 0 0 0 0.2rem #818df84d;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: #eef0f3;
}

@media (max-width: 575.98px) {
  .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    min-width: 125px !important;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    padding: 0.5rem 1rem;
  }
  .p-datatable .p-datatable-tbody > tr > td:first-child {
    padding-top: 1rem;
  }
  .p-datatable .p-datatable-tbody > tr > td:last-child {
    padding-bottom: 1rem;
  }
}
@media (max-width: 575.98px) {
  .p-confirm-popup {
    width: 90vw;
    left: 50% !important;
    transform: translate(-50%) !important;
  }
}

.ng-trigger.ng-trigger-animation.ng-tns-c59-4.p-shadow-2.p-confirm-popup.p-component.ng-star-inserted {
  z-index: 12000 !important;
}

.p-tooltip {
  z-index: 12000 !important;
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.p-chips-multiple-container {
  width: 100%;
}

@media screen and (max-width: 500px) {
  .p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th,
.p-datatable.p-datatable-responsive-demo .p-datatable-tfoot > tr > td {
    display: none !important;
  }
  .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td {
    text-align: left;
    display: flex;
    width: 100%;
    float: left;
    clear: left;
    border: 0 none;
  }
  .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4rem;
    font-weight: bold;
  }
  .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td:last-child {
    border-bottom: 1px solid var(--surface-d);
  }
}
.theme-dark .p-field > label,
.theme-dark input,
.theme-dark textarea,
.theme-dark label,
.theme-dark .p-tabview .p-tabview-nav li .p-tabview-nav-link,
.theme-dark .p-chips .p-chips-multiple-container .p-chips-input-token input {
  color: #a2a3b7;
}
.theme-dark h2,
.theme-dark h4 {
  color: #fff;
}
.theme-dark .header-main-logo {
  filter: brightness(0) invert(1);
}
.theme-dark .main-content {
  background-color: #26263c;
}
.theme-dark .main-card .card-title {
  color: #fff;
}
.theme-dark nav {
  background-color: #1f1f31 !important;
  border-right: 1px solid #323249;
}
.theme-dark nav .nav-top-header {
  background-color: #1f1f31 !important;
  border-bottom: 1px solid #323249;
}
@media (max-width: 575.98px) {
  .theme-dark nav .nav-top-header {
    border-bottom: none;
  }
}
.theme-dark .dashboard-card {
  background-color: #1f1f31;
  border-radius: 4px;
  padding: 1.5rem;
  border: none;
}
@media (max-width: 575.98px) {
  .theme-dark .dashboard-card {
    padding: 1rem;
  }
}
.theme-dark .dashboard-card span {
  color: #a2a3b7;
}
.theme-dark .dashboard-card.numbers h1 {
  color: #fff;
}
.theme-dark .dashboard-card.numbers span {
  color: #a2a3b7;
}
.theme-dark .top-header {
  background-color: #26263c !important;
  border-bottom: 1px solid #323249;
  box-shadow: none !important;
}
.theme-dark .top-header .user-options .user-img {
  color: #2187ec;
  background-color: #d9ecff05 !important;
}
.theme-dark .top-header .user-options .user-option-dropdown {
  box-shadow: none;
}
.theme-dark .top-header .user-options span {
  color: #fff !important;
}
.theme-dark .p-sidebar {
  background-color: #26263c;
}
.theme-dark .p-confirm-popup .p-confirm-popup-footer {
  background-color: #1f1f31 !important;
  background: #1f1f31 !important;
}
.theme-dark .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border-bottom: 3px solid transparent;
}
.theme-dark .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link:focus,
.theme-dark .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}
.theme-dark .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background-color: transparent;
  border-bottom: 3px solid #1f1f31;
  color: #fff;
}
.theme-dark .p-tabview .p-tabview-nav {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #323249;
}
.theme-dark .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background-color: transparent !important;
  color: #fff;
  border-bottom: 3px solid #2187ec !important;
}
.theme-dark .p-breadcrumb {
  background: transparent;
}
.theme-dark .p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: #2187ec;
}
.theme-dark .p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: #a2a3b7;
  transition: color 0.3s;
}
.theme-dark .p-breadcrumb ul li .p-menuitem-link .p-menuitem-text:hover {
  color: #fff;
  transition: color 0.3s;
}
.theme-dark .p-breadcrumb ul li .p-menuitem-link:focus {
  box-shadow: none;
}
.theme-dark .p-breadcrumb ul li:last-child .p-menuitem-link .p-menuitem-text {
  color: #fff;
  cursor: default;
}
.theme-dark .media-preview span,
.theme-dark .media-preview i {
  color: #a2a3b7;
}
.theme-dark .p-fileupload .p-fileupload-buttonbar {
  background-color: #1d1d2e;
  border: none;
}
.theme-dark .p-fileupload .p-fileupload-content {
  background-color: #1f1f31;
  border: none;
}
.theme-dark .p-fileupload .drag-and-drop {
  background-color: #1f1f31;
  border: none;
}
.theme-dark .p-fileupload .drag-and-drop .drop-border {
  background-image: repeating-linear-gradient(0deg, #323249, #323249 14px, transparent 14px, transparent 34px, #323249 34px), repeating-linear-gradient(90deg, #323249, #323249 14px, transparent 14px, transparent 34px, #323249 34px), repeating-linear-gradient(180deg, #323249, #323249 14px, transparent 14px, transparent 34px, #323249 34px), repeating-linear-gradient(270deg, #323249, #323249 14px, transparent 14px, transparent 34px, #323249 34px) !important;
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}
.theme-dark .p-fileupload .drag-and-drop .drop-border span {
  color: #a2a3b7;
}
.theme-dark .p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
  background-color: #26263c;
  border: none;
  color: #a2a3b7;
  font-weight: 500;
}
.theme-dark .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background-color: #26263c;
  border-color: transparent;
  border-bottom: 1px solid #323249;
  color: #fff;
  font-weight: 500;
}
.theme-dark .p-accordion .p-accordion-content {
  background-color: #26263c;
  border-color: transparent;
}
.theme-dark .page-seo .p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
  background-color: #1f1f31;
}
.theme-dark .page-seo .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background-color: #1f1f31;
}
.theme-dark .p-panel .p-panel-header {
  background-color: #1d1d2e;
  border-color: transparent;
  color: #fff;
}
.theme-dark .main-card h3 {
  color: #fff;
}
.theme-dark .p-panel .p-panel-content {
  background-color: #1f1f31 !important;
  background-color: transparent;
  border-color: transparent;
}
.theme-dark .reorder-body {
  background-color: #26263c !important;
  border-color: transparent;
  border-radius: 4px;
  color: #a2a3b7;
  margin-bottom: 1rem !important;
}
.theme-dark .reorder-handle {
  background-color: #1f1f31 !important;
  border-color: transparent;
}
.theme-dark .second-btn {
  background-color: #1f1f31 !important;
  border-color: transparent;
  color: #fff;
}
.theme-dark .p-chips .p-chips-multiple-container .p-chips-token {
  color: #fff;
  background-color: #00cf8a !important;
}
.theme-dark .red-chips .p-chips-multiple-container .p-chips-token {
  background-color: #FC035F !important;
  color: #fff;
}
.theme-dark .p-dialog .p-dialog-header,
.theme-dark .p-dialog .p-dialog-content,
.theme-dark .p-dialog .p-dialog-footer {
  background-color: #26263c;
}
.theme-dark .p-dialog .p-dialog-header .p-dialog-title {
  color: #fff;
}
.theme-dark .p-dialog .p-dialog-footer button {
  color: #fff;
}
.theme-dark .menu-item {
  background-color: #26263c !important;
  border-color: transparent !important;
}
.theme-dark .menu-item span {
  color: #fff;
}
.theme-dark .submenu {
  background-color: #1f1f31 !important;
}
.theme-dark .p-skeleton {
  background-color: rgba(31, 31, 49, 0.06);
}
.theme-dark .p-skeleton:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0));
}
.theme-dark .p-datatable .p-datatable-tbody > tr {
  background-color: transparent;
}
.theme-dark .p-datatable .p-datatable-tbody > tr > td {
  border: none;
  border-bottom: 1px solid #323249 !important;
  color: #fff;
}
.theme-dark .p-datatable .p-datatable-thead > tr > th {
  background-color: #323249;
  color: #fff;
  border: none;
}
.theme-dark .p-datatable .p-datatable-header {
  border-color: transparent;
  background-color: transparent;
  padding-right: 0px;
}
@media (max-width: 575.98px) {
  .theme-dark .p-datatable .p-datatable-header {
    padding: 1rem;
  }
}
.theme-dark .p-paginator {
  background-color: transparent;
}
.theme-dark .image-upload .upload-item {
  background-color: #26263c;
  padding: 0.5rem;
  border-radius: 3px;
}
.theme-dark .p-fileupload .p-fileupload-content {
  padding: 1rem;
}
.theme-dark .help-component .p-tag.p-tag-info {
  background-color: #2187ec;
}
.theme-dark .help-component span {
  color: #a2a3b7;
}
.theme-dark .help-component .p-tag span {
  color: #fff;
}
.theme-dark .block-ui .block-ui-message {
  background-color: #26263c !important;
}
.theme-dark .block-ui .block-ui-message .pi-spinner:before {
  color: #2187ec;
}
.theme-dark .items-for-upload {
  background-color: #1d1d2e !important;
  border: none !important;
}
.theme-dark .items-for-upload .single-item {
  background-color: #26263c !important;
  border: none !important;
}
.theme-dark .items-for-upload .single-item span,
.theme-dark .items-for-upload .single-item i {
  color: #fff !important;
}
.theme-dark .main-btn.disabled {
  background-color: #d5daea21;
  color: #a9a9c1;
  cursor: no-drop;
}
.theme-dark .main-btn,
.theme-dark .image-upload .file > label,
.theme-dark .p-button {
  background-color: #2187ec;
}
.theme-dark .main-btn:hover,
.theme-dark .image-upload .file > label:hover,
.theme-dark .p-button:hover {
  background-color: #137ae0;
}
.theme-dark .p-button.p-button-text {
  background-color: transparent;
}
.theme-dark ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #54556c;
  opacity: 1;
  /* Firefox */
}
.theme-dark :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #54556c;
}
.theme-dark ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #54556c;
}
.theme-dark .p-dropdown .p-dropdown-label.p-placeholder {
  color: #54556c !important;
}
.theme-dark .ql-editor.ql-blank::before {
  color: #54556c;
}
.theme-dark .p-inputtext,
.theme-dark .ql-editor,
.theme-dark .p-dropdown,
.theme-dark .ql-container.ql-snow {
  background-color: #1f1f31;
  border: 1px solid #1f1f31 !important;
  color: #fff !important;
}
.theme-dark .p-button:focus {
  box-shadow: none;
}
.theme-dark .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #26263c;
  color: #fff;
}
.theme-dark .ql-toolbar {
  background-color: #1d1d2e !important;
  border: 1px solid #1f1f31 !important;
}
.theme-dark .ql-snow .ql-stroke {
  stroke: #fff;
}
.theme-dark .ql-snow.ql-toolbar button svg,
.theme-dark .ql-snow .ql-toolbar button svg {
  stroke: #fff;
}
.theme-dark .ql-snow .ql-picker-label {
  color: #fff;
}
.theme-dark .p-dropdown-panel {
  background-color: #1f1f31;
}
.theme-dark .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: #26263c;
  color: #fff;
}
.theme-dark .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: #a2a3b7;
}
.theme-dark .p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: none;
}
.theme-dark .p-inputtext:enabled:focus,
.theme-dark .p-dropdown:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.1rem #2187ec;
}
.theme-dark .p-selectbutton .p-button {
  background-color: #1f1f31;
  color: #a2a3b7;
  border: transparent;
}
.theme-dark .p-selectbutton .p-button.p-highlight {
  background-color: #2187ec !important;
  color: #fff;
}
.theme-dark .p-inputswitch .p-inputswitch-slider {
  background: #1f1f31;
}
.theme-dark .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #2187ec;
}
.theme-dark .main-btn.back-btn {
  background-color: #d9ecff05;
}